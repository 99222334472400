import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue")
  },
  {
    path: "/updatepwd",
    name: "UpdatePwd",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UpdatePwd.vue")
  },
  {
    path: "/justwenlogin",
    name: "AdminLogin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminLogin.vue")
  },
  {
    path: "/adminhome",
    name: "AdminHome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminHome.vue")
  },
  {
    path: "/stuhome",
    name: "StuHome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StuHome.vue"),
    children: []
  },
  {
    path: "/stushow",
    name: "StuShow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StuShow.vue")
  },
  {
    path: "/thome",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TecHome.vue")
  },
  {
    path: "/thome/:id",
    name: "TecHome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TecHome.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
