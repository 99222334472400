import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // baseUrl: "http://192.168.3.14:100",
    baseUrl: "https://yy.justwen233.cn:100",
    stushowdata: {}
  },
  mutations: {},
  actions: {},
  modules: {}
});
