import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import jsCookie from "js-cookie";
import axios from "axios";
import SlideVerify from "vue-monoplasty-slide-verify";
import router from "./router";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
var $http = axios.create({
  timeout: "3000" //请求超时时间
});
Vue.prototype.$http = $http;
Vue.prototype.$Cookie = jsCookie;
Vue.use(ElementUI);
Vue.use(SlideVerify);
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
